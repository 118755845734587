import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import StickyBar from "./StickyBar";
import TopBar from "./TopBar";

export default function Song() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    // Access query parameters
    const songTitle = queryParams.get('title');
    const [lyrics, setLyrics] = useState('');

    useEffect(() => {
        fetch('/lyrics/' + songTitle + '.html')
            .then(response => response.text())
            .then(data => {
                setLyrics(data);
            });
    }, [songTitle]);

    return (
        <div className={'flex flex-col p-14 sm:p-15 text-black'}>
            <TopBar/>
            {lyrics.split('\n').map((line, index) => {
                if (index % 2 !== 0) {
                    return <pre key={index} style={{wordBreak: 'break-word', whiteSpace: 'pre-wrap'}}>{line.trimEnd()}<br/></pre>
                } else {
                    return <pre key={index} style={{wordBreak: 'break-word', whiteSpace: 'pre-wrap'}}><b>{line.trimEnd()}</b><br/></pre>

                }
            })}
            <StickyBar/>
        </div>
    );
}