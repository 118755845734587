import React, { useState, useEffect } from 'react';

export default function StickyBar() {
    const [isScrolling, setIsScrolling] = useState(false);

    useEffect(() => {
        let scrollInterval;
        if (isScrolling) {
            scrollInterval = setInterval(() => {
                window.scrollBy(0, 1);
            }, 200);
        } else {
            clearInterval(scrollInterval);
        }
        return () => clearInterval(scrollInterval);
    }, [isScrolling]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'r') {
                setIsScrolling(!isScrolling);
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [isScrolling]);

    return (
        <div style={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            margin: 'auto',
            backgroundColor: '#f8f9fa',
            padding: '10px 0',
            display: 'flex',
            justifyContent: 'center',
            zIndex: 1000,
            opacity: isScrolling ? 0.5 : 1
        }}>
            <button onClick={() => setIsScrolling(!isScrolling)}>
                {isScrolling ? '⏹' : '▶'}
            </button>
        </div>
    );
}