import Album from "../components/Album";
import MusicService from "../components/MusicService";
import spotifyLogo from '../assets/images/services/spotify.png';
import appleLogo from '../assets/images/services/apple.svg';
import amazonLogo from '../assets/images/services/amazon.png';
import tiktokLogo from '../assets/images/services/tiktok.png';
import distribution from '../assets/images/distribution.webp';

const musicServices = [
    {
        name: 'Apple Music',
        image: appleLogo,
        link: 'https://music.apple.com/it/album/cos%C3%AC-sia/1739711753?i=1739711756',
    },
    {
        name: 'Spotify',
        image: spotifyLogo,
        link: 'https://open.spotify.com/intl-it/track/5nuR928TzYpx9KZ6MNGHgA?si=f7a61b68f41e40e8',
    },
    {
        name: 'Amazon Music',
        image: amazonLogo,
        link: 'https://music.amazon.it/albums/B0CZTF3L4R?marketplaceId=APJ6JRA9NG5V4&musicTerritory=IT&ref=dm_sh_B7BLHs9SwlMkK6t35Kfj3mGaL&trackAsin=B0CZTFD7PK',
    },
    {
        name: 'TikTok',
        image: tiktokLogo,
        link: 'https://www.tiktok.com/@yasperofficial',
    },
]

export default function Homepage() {
    return (
        <div className={'flex items-center justify-between h-[calc(100vh_-_3rem)] flex-col p-8 sm:p-15'}>
            <Album/>
            {musicServices.map((service) => (
                <MusicService
                    key={service.name}
                    link={service.link}
                    image={service.image}
                />
            ))}
            <div className={"flex flex-col items-center"}>
                <p className="text-center text-xs text-gray-500 mb-2">© 2023 YASPER - Così Sia distributed by</p>
                <img className={'w-32'} src={distribution} alt='Distribution'/>
            </div>
        </div>
    );
}
