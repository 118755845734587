import React, {useState} from 'react';

export default function Repertoire() {
    const [searchTerm, setSearchTerm] = useState('');

    const songs = [
        {name: 'All I Want - Kodaline'},
        {name: 'Wicked Game - Chris Isaak'},
        {name: 'Unsteady - X Ambassador'},
        {name: 'Luce (Tramonti A Nord Est) - Elisa'},
        {name: 'We Found Love - Rihanna'},
        {name: 'D\'improvviso - Lorenzo Fragola'},
        {name: 'lovely - Billie Eilish'},
        {name: 'Fix You - Coldplay'},
        {name: 'The Scientist - Coldplay'},
        {name: 'Use Somebody - Kings of Leon'},
    ];

    const songLinks = songs.map(song => ({
        title: song.name,
        link: '/#/song?title=' + song.name.toLowerCase().split(' ').join('-').replace(/-+/g, '-')})
    );

    const filteredSongs = songLinks.filter(song =>
        song.title.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className={'flex items-center h-[calc(100vh_-_5rem)] flex-col p-14 sm:p-15 text-black'}>
            <h1 className={"p-3"}><b>SONGS</b></h1>
            <input
                className={"p-3 m-5"}
                type="text"
                placeholder="Search songs..."
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
            />
            <ul>
                {filteredSongs.map(song => (
                    <li className={"p-2"} key={song}>
                        <a href={song.link}>{song.title}</a>
                    </li>
                ))}
            </ul>
        </div>
    );
}