import React from 'react';
import { Link } from 'react-router-dom';

export default function TopBar() {
    return (
        <div style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            height: '50px',
            backgroundColor: 'transparent',
            display: 'flex',
            alignItems: 'center',
            paddingLeft: '10px',
            zIndex: 1000
        }}>
            <Link to="/repertoire">
                <svg className={"ml-3 mt-3"} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="white" style={{width: '24px', height: '24px'}}>
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                </svg>
            </Link>
        </div>
    );
}